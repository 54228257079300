import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Text,
  Box
} from '@chakra-ui/react';

const BroadcastModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [notification, setNotification] = useState(null);

  // 1) Cargar "dismissed" desde localStorage
  const [dismissedIds, setDismissedIds] = useState(() => {
    const stored = localStorage.getItem('dismissedBroadcastNotifications');
    return stored ? JSON.parse(stored) : [];
  });

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        // Ajusta la obtención de token según tu proyecto:
        const token = localStorage.getItem('USER_AUTH') || sessionStorage.getItem('USER_AUTH');
        
        // Ajusta la URL según tu configuración (con /api/ o sin ella)
        const response = await axios.get('/notificaciones/', {
          headers: { Authorization: `Bearer ${token}` },
        });

        let data = response.data;
        // Si la respuesta está paginada => { results: [] }
        if (data && Array.isArray(data.results)) {
          data = data.results;
        } else if (!Array.isArray(data)) {
          data = [];
        }

        // 2) Buscar la primera notificación broadcast que NO esté en dismissedIds
        const broadcastNotif = data.find(
          (n) => n.is_broadcast === true && !dismissedIds.includes(n.id)
        );

        // 3) Si existe, la mostramos
        if (broadcastNotif) {
          setNotification(broadcastNotif);
          onOpen();
        }
      } catch (error) {
        console.error('Error al obtener notificaciones: ', error);
      }
    };

    fetchNotifications();
  }, [onOpen, dismissedIds]);

  // 4) Al cerrar, marcamos la notificación como descartada
  const handleClose = () => {
    if (notification) {
      const newDismissed = [...dismissedIds, notification.id];
      setDismissedIds(newDismissed);
      localStorage.setItem(
        'dismissedBroadcastNotifications',
        JSON.stringify(newDismissed)
      );
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen && notification} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {notification?.titulo || 'Aviso Importante'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* 
            Usamos Box + dangerouslySetInnerHTML para renderizar HTML si 
            tu notificación está en formato HTML (por ej. con <p>, <b>, emojis, etc.)
          */}
          <Box 
            dangerouslySetInnerHTML={{ __html: notification?.mensaje }} 
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleClose}>
            Cerrar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BroadcastModal;