import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { UseDataUserProvider } from './_Hooks/_Context/useDataUserAuthenticated';
import { NotificationsProvider } from 'reapop';
import { pdfjs } from 'react-pdf';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
// ↑ Puedes usar "ColorModeScript" si quieres que Chakra recuerde el modo seleccionado

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <NotificationsProvider>
      <UseDataUserProvider>
        {/* Envuelve todo en ChakraProvider */}
        <ChakraProvider>
          {/*
            Opcional: <ColorModeScript />
            Esto permite que Chakra recuerde el modo 
            y cargue el preferido al recargar la página.
          */}
          <ColorModeScript />
          <App />
        </ChakraProvider>
      </UseDataUserProvider>
    </NotificationsProvider>
  </React.StrictMode>
);