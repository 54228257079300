// src/Layouts/AppLayout.jsx (o como lo llames)
// Ajusta la ruta a tu preferencia
import React from "react";
import { Flex, Box, useColorModeValue } from "@chakra-ui/react";
import { AdminSidebarApp, HeaderNavbar } from "./_NavigationApp";
import { FloatingChatBubbleChakra } from "../Private/chatia/FloatingChatBubbleChakra";

// IMPORTAMOS nuestro modal de notificaciones broadcast
import BroadcastModal from "../Private/Notificaciones/BroadcastModal";

// Layout público (sin sidebar, por ejemplo)
export const LayoutPublic = ({ children }) => {
  return <>{children}</>;
};

// Layout privado
export const LayoutPrivate = ({ children }) => {
  const bgMain = useColorModeValue("gray.50", "gray.900");

  return (
    <Flex h="100vh">
      {/* Sidebar izquierdo */}
      <AdminSidebarApp />

      {/* Sección derecha: encabezado + contenido + burbujas */}
      <Flex direction="column" flex="1" bg={bgMain}>
        {/* Barra superior */}
        <HeaderNavbar />

        {/* Contenido principal */}
        <Box p={4} flex="1" overflowY="auto">
          {children}
        </Box>

        {/* Aquí incluyes el modal de notificaciones broadcast */}
        <BroadcastModal />

        {/* Burbujita de chat (opcional) */}
        <FloatingChatBubbleChakra />
      </Flex>
    </Flex>
  );
};